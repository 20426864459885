/**
 * Блок подключения модулей
 */
/**
 * React
 */
import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import logo from '../img/asset.png';

moment.locale('ru');

export const IndexPage = () => {
  return (
    <>
      <div
        className={
          ' grid' +
          ' content-center' +
          ' bg-hls' +
          ' w-full' +
          ' p-8' +
          ' min-h-40' +
          ' top-0' +
          ' align-middle' +
          ' fixed' +
          ' relative' +
          ' z-30'
        }
      >
        <div
          className={
            ' grid' +
            ' md:grid-cols-3' +
            ' container' +
            ' gap-4' +
            ' place-items-center'
          }
        >
          <a href={'/'}>
            <img
              className={
                ' logo' +
                ' transition' +
                ' ease-in-out' +
                ' hover:brightness-125' +
                ' active:brightness-100' +
                ' duration-150'
              }
              alt={'Ошибка загрузки изображения'}
              src={logo}
            />
          </a>
          <div className={'md:hidden'}>
            <div className={
              'hls-stripe-first w-4 rotate-45'
            }>
              &nbsp;
            </div>
          </div>
          <div
            className={
              ' grid' +
              ' grid-cols-3' +
              ' text-center' +
              ' content-center' +
              ' gap-8' +
              ' font-black' +
              ' text-neutral-50'
            }
          >
            <p>
              <a href={'/'}>
                О НАС
              </a>
            </p>
            <p>
              <a href={'/'}>
                О НАС
              </a>
            </p>
            <p>
              <a href={'/'}>
                О НАС
              </a>
            </p>
          </div>
        </div>
      </div>
      {/* Second block */}
      <div
        className={
          ' bg-neutral-950' +
          ' text-neutral-50' +
          ' w-full'
        }
      >
        <div className={'container'}>
          <div
            className={
              'bg-hls w-1/2 rotate-45 h-1/2 absolute z-20'
            }>
            &nbsp;
          </div>
          <div
            className={
              'bg-hls-two w-1/2 rotate-45 h-1/2 absolute mt-8 mr-8 z-10'
            }>
            &nbsp;
          </div>
          <div
            className={
              'text-justify p-8 relative z-30'
            }>
            <p
              className={
                'uppercase font-black text-center text-2xl sm:text-4xl'
              }
            >
              Цели и ценности
            </p>
            <div
              className={'text-xl text-right'}>
              <p
                className={'mb-8'}
              >
                В эпоху тестов и заучивания многие стали
                осознавать <strong>недостаточность</strong> базового школьного
                образования. Бесконечные уроки и репетиторы могут легко <strong>отбить
                интерес</strong> к предмету или учебе в целом. Мы понимаем эту
                проблему и <strong>мечтаем вернуть</strong> в образование
                глубокое понимание предмета, заинтересованность ученика и
                человеческое отношение учителя.
              </p>
              <p>
                Школа <strong>HOBBY LAB</strong> была создана для того, чтобы
                внутри каждого снова разбудить <strong>любопытного
                ребенка</strong> и вернуть интерес к процессу обучения и
                познания.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}