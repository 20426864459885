import React, {Fragment} from 'react';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';

function App() {
  return (
    <Router>
      <Fragment>
        <Routes>
          <Route path={'/'} element={<IndexPage/>} exact/>
        </Routes>
      </Fragment>
    </Router>
  );
}

export default App;